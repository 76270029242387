(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-outcome-pairs.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-outcome-pairs.js');
"use strict";


const {
  useMemo
} = React;
const {
  expandErSROWHexBoardToOutcomeIndexes
} = svs.components.tipsen.expandedSystems;
const {
  tipsenApi
} = svs.components.tipsen.store;
const composeXperten = boards => {
  const outcomePairs = [];
  for (const board of boards) {
    board.forEach((outcomeGroup, index) => {
      const outcomePair = Array.prototype.concat.apply([], outcomeGroup);
      const eventPairs = outcomePairs[index] || [];
      const outcomePairStr = outcomePair.join();
      const isNotUnique = eventPairs.some(pair => pair.join() === outcomePairStr);
      outcomePairs[index] = eventPairs.concat(isNotUnique ? [] : [outcomePair]);
    });
  }
  return outcomePairs;
};
const useOutcomePairs = _ref => {
  var _wager$productBet2;
  let {
    wagerId
  } = _ref;
  const {
    data: wager
  } = tipsenApi.useGetPlayerWagerQuery({
    wagerId
  }, {
    skip: !wagerId
  });
  const boardsToCompose = useMemo(() => {
    var _wager$productBet$gam, _wager$productBet;
    const boards = (_wager$productBet$gam = wager === null || wager === void 0 || (_wager$productBet = wager.productBet) === null || _wager$productBet === void 0 || (_wager$productBet = _wager$productBet.gameBoard) === null || _wager$productBet === void 0 || (_wager$productBet = _wager$productBet.boards) === null || _wager$productBet === void 0 || (_wager$productBet = _wager$productBet.boardData) === null || _wager$productBet === void 0 ? void 0 : _wager$productBet.boards) !== null && _wager$productBet$gam !== void 0 ? _wager$productBet$gam : [];
    const outcomePairs = boards.map(sRow => {
      const boardPositions = expandErSROWHexBoardToOutcomeIndexes(sRow);
      const outcomeGroups = [];
      let outcomeGroup = [];
      boardPositions.forEach(sign => {
        outcomeGroup.push([sign.toString()]);
        if (outcomeGroup.length === 2) {
          outcomeGroups.push(outcomeGroup);
          outcomeGroup = [];
        }
      });
      return outcomeGroups;
    });
    return outcomePairs;
  }, [wager === null || wager === void 0 || (_wager$productBet2 = wager.productBet) === null || _wager$productBet2 === void 0 || (_wager$productBet2 = _wager$productBet2.gameBoard) === null || _wager$productBet2 === void 0 || (_wager$productBet2 = _wager$productBet2.boards) === null || _wager$productBet2 === void 0 || (_wager$productBet2 = _wager$productBet2.boardData) === null || _wager$productBet2 === void 0 ? void 0 : _wager$productBet2.boards]);
  const boardsWithOutcomePairs = useMemo(() => composeXperten(boardsToCompose), [boardsToCompose]);
  return boardsWithOutcomePairs;
};
setGlobal('svs.components.tipsen.hooksWager.useOutcomePairs', useOutcomePairs);

 })(window);